<template>
	<div class="grid">
		<div class="col-12">
			<div class="card">
				industry33 {{perf}}
				<DataTable :value="industries" class="p-datatable-sm" 
					v-model:filters="filters1" responsiveLayout="scroll" showGridlines stripedRows
					:globalFilterFields="['industry_cd', 'industry_name', 'industry_name_en', 'industry_name_ko']"
					selectionMode="single">
					<template #header>
						<span class="p-input-icon-left">
							<i class="pi pi-search" />
							<InputText v-model="filters1['global'].value" placeholder="キーワード" />
						</span>
					</template>
					<Column field="id" header="No" :sortable="true"></Column>
					<Column field="industry_cd" header="コード" :sortable="true"></Column>
					<Column field="index_cd" header="index_cd" :sortable="true"></Column>
					<Column field="industry_name" header="業種" :sortable="true"></Column>
					<Column field="industry_name_en" header="Industry" :sortable="true"></Column>
					<Column field="industry_name_ko" header="업종" :sortable="true"></Column>
				</DataTable>
			</div>
		</div>
	</div>
</template>

<script>
import { FilterMatchMode } from 'primevue/api';
import IndustryService from '@/service/IndustryService';

export default {
	data() {
		return {
			industries: null,
			filters1: {},
			perf: null,
		}
	},
	industryService: null,
	created() {
		this.industryService = new IndustryService();
		this.initFilters1();
	},
	mounted() {
		this.industryService.getAll()
			.then(d => {
				this.industries = d.data;
				this.perf = d.perf;
			}
		);
	},
	methods: {
		initFilters1() {
			this.filters1 = {
				'global': {value: null, matchMode: FilterMatchMode.CONTAINS}
			}
		}
	}
}
</script>

<style scoped>

</style>
